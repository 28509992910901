import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FaCopy, FaCheck } from "react-icons/fa";

const CodeBlock = ({ language, children }) => {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative group">
      <button
        onClick={handleCopy}
        className="absolute right-2 top-2 p-2 rounded-lg bg-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        title="Copy code"
      >
        {copied ? (
          <FaCheck className="h-4 w-4 text-green-500" />
        ) : (
          <FaCopy className="h-4 w-4 text-gray-400 hover:text-white" />
        )}
      </button>
      <SyntaxHighlighter
        style={oneDark}
        language={language}
        PreTag="div"
        customStyle={{
          marginTop: 0,
          marginBottom: 0,
          paddingRight: "3rem",
        }}
      >
        {children}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
