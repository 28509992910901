import React, { useState, useRef, useEffect } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import { Storage } from "aws-amplify";

const AudioPlayer = ({ audioUrl, duration }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  const audioRef = useRef(new Audio());
  const progressRef = useRef(null);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        let signedUrl = audioUrl;
        // If it's an S3 URL, get a signed URL
        if (audioUrl.startsWith("s3://")) {
          // Remove the s3:// prefix and public/ from the path
          const s3Key = audioUrl.replace(
            "s3://sapienslab-storage-dev-user-files/public/",
            ""
          );
          signedUrl = await Storage.get(s3Key);
        }
        setAudioSrc(signedUrl);
        audioRef.current.src = signedUrl;
      } catch (error) {
        console.error("Error fetching audio:", error);
      }
    };

    fetchAudio();

    return () => {
      audioRef.current.pause();
      audioRef.current.src = "";
    };
  }, [audioUrl]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      audio.currentTime = 0;
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressClick = (e) => {
    const progressBar = progressRef.current;
    const rect = progressBar.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    const newTime = percentage * (duration || audioRef.current.duration);
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div className="flex items-center gap-3 bg-gray-700 rounded-lg p-2 max-w-xs">
      <button
        onClick={togglePlayPause}
        className="w-8 h-8 flex items-center justify-center bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
      >
        {isPlaying ? (
          <FaPause size={12} />
        ) : (
          <FaPlay size={12} className="ml-1" />
        )}
      </button>

      <div className="flex-grow">
        <div
          ref={progressRef}
          onClick={handleProgressClick}
          className="h-1 bg-gray-600 rounded-full cursor-pointer"
        >
          <div
            className="h-full bg-blue-500 rounded-full relative"
            style={{
              width: `${(currentTime / (duration || audioRef.current.duration)) * 100 || 0}%`,
            }}
          >
            <div className="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 w-2.5 h-2.5 bg-blue-500 rounded-full" />
          </div>
        </div>
        <div className="flex justify-between text-xs text-gray-400 mt-1">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration || 0)}</span>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
