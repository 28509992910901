import React from "react";
import ReactMarkdown from "react-markdown";
import jarvisImage from "../../img/jarvis_ai_sapiens_small.png";
import samImage from "../../img/sam_ai_sapiens_small.jpg";
import { FaUser } from "react-icons/fa";
import CodeBlock from "./CodeBlock";
import AudioPlayer from "./AudioPlayer";

const MessageList = ({ messages, messagesEndRef, chatBotId }) => {
  const parseMessage = (messageText) => {
    // Handle null or undefined messageText
    if (!messageText) {
      return { text: "", attachment: null };
    }

    // First check for file attachments
    const fileRegex = /\[File: (.*?)\]\((.*?)\)/;
    const match = messageText.match(fileRegex);

    if (match) {
      const fileName = match[1];
      const fileUrl = match[2];
      const isPdf = fileName.toLowerCase().endsWith(".pdf");
      const isAudio = fileName.toLowerCase().endsWith(".webm");
      const duration = messageText.match(/\[duration: (\d+)\]/)?.[1];

      return {
        text: messageText
          .replace(match[0], "")
          .replace(/\[duration: \d+\]/, "")
          .trim(),
        attachment: {
          name: fileName,
          url: fileUrl,
          type: isPdf ? "application/pdf" : isAudio ? "audio/webm" : "unknown",
          isPDF: isPdf,
          isAudio: isAudio,
          duration: duration ? parseInt(duration) : null,
        },
      };
    }

    // If no file attachment, check for URLs and format them as markdown links
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const formattedText = messageText.replace(urlRegex, (url) => {
      // Remove any trailing punctuation that might have been caught
      const cleanUrl = url.replace(/[.,;!?]$/, "");
      return `[${cleanUrl}](${cleanUrl})`;
    });

    return { text: formattedText, attachment: null };
  };

  const renderMessage = (message) => {
    // Handle null or undefined message
    if (!message || !message.msg) {
      message = {
        ...message,
        msg: "",
      };
    }

    const parsedMessage = parseMessage(message.msg);
    const isPDF = parsedMessage.attachment?.type === "application/pdf";
    const isAudio = parsedMessage.attachment?.type === "audio/webm";

    return (
      <div
        key={message.id}
        className={`flex items-start ${
          message.type === "human" ? "justify-end" : "justify-start"
        } mb-4 gap-2`}
      >
        {message.type !== "human" && (
          <img
            src={chatBotId === "0" ? jarvisImage : samImage}
            alt="AI Avatar"
            className="w-8 h-8 rounded-full object-cover"
          />
        )}
        <div
          className={`max-w-[80%] rounded-lg p-4 ${
            message.type === "human"
              ? "bg-blue-600 text-white"
              : "bg-gray-800 text-white"
          }`}
        >
          {parsedMessage.text && (
            <div className="prose prose-invert max-w-none">
              <ReactMarkdown
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    const language = match ? match[1] : "";
                    return !inline && match ? (
                      <CodeBlock language={language}>
                        {String(children).replace(/\n$/, "")}
                      </CodeBlock>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  // Add custom link renderer
                  a({ node, href, children, ...props }) {
                    return (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-300 underline"
                        {...props}
                      >
                        {children}
                      </a>
                    );
                  },
                }}
              >
                {parsedMessage.text}
              </ReactMarkdown>
            </div>
          )}

          {parsedMessage.attachment && isPDF && (
            <div className="mt-2 flex items-center gap-2 p-2 rounded-lg bg-gray-700">
              <div className="w-8 h-8 flex items-center justify-center bg-pink-500 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <a
                href={parsedMessage.attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-blue-400 hover:underline"
              >
                {parsedMessage.attachment.name}
              </a>
            </div>
          )}

          {parsedMessage.attachment && isAudio && (
            <div className="mt-2">
              <AudioPlayer
                audioUrl={parsedMessage.attachment.url}
                duration={parsedMessage.attachment.duration}
              />
            </div>
          )}
        </div>
        {message.type === "human" && (
          <div className="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center">
            <FaUser className="text-white text-sm" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-4 p-4">
      {messages.map(renderMessage)}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
